import { ThemeProvider, StyleReset, Text, Div, Container } from "atomize"
import React, { useEffect } from "react"
import Footer from "../components/footer"
import SEO from "../components/seo"
import "../settings/index.css"
import Header from "../components/header"
let config = require("../settings/config")
let legal = require("../components/legal")

const theme = config.theme
const legalNotice = legal.legalNotice

const LegalNotice = () => {
    return (
        <ThemeProvider theme={theme}>
            <StyleReset />

            <Header />
            <SEO
                title="Consulta la pagina Note Legali del nostro sito"
                description="Offriamo un servizio di rivendita delle eccedenze alimentari che consente l'acquisto di cibo da asporto a prezzi vantaggiosi. Scarica subito la nostra app!"
                pathname="note-legali"
            />

            <main style={{ position: "relative", overflow: "hidden" }}>
                <section>
                    <Div
                        bg="white"
                        pos="relative"
                        zIndex="1"
                        p={{ t: { xs: "0", md: "1rem", lg: "6rem", xl: "7rem" } }}
                    >
                        <Container>
                            <Text tag="h1" d="none">
                                Di seguito sono riportate le nostre note legali
                            </Text>
                            <Text
                                p={{ y: { xs: "2rem", lg: "0" } }}
                                textAlign="center"
                                textSize={{ xs: "display1", md: "display2", lg: "display3" }}
                                textColor="gunmetal"
                                textWeight="bold"
                            >
                                Note legali
                            </Text>
                            {legalNotice}
                        </Container>
                    </Div>
                </section>
            </main>
            <Footer />
        </ThemeProvider>
    )
}

export default LegalNotice
